<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu thông tin (Ctrl + S)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies" :class="{'md-invalid': submitted && $v.entity.companyId.$error }">
                                    <label>Vendor</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.companyId.isSelected">Vui lòng chọn vendor</span>
                                </md-autocomplete>
                                <md-button @click="openCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm vendor</md-tooltip>
                                </md-button>
                            </div>
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Người lập phiếu</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.debtCode.$error }">
                               <label for="debtCode">Mã phiếu</label>
                               <md-input name="debtCode" v-model="entity.debtCode"></md-input>
                               <span class="md-error" v-if="submitted && !$v.entity.debtCode.required">Vui lòng nhập mã phiếu</span>
                            </md-field>
                            
                            <md-field>
                               <label for="note">Nội dung</label>
                               <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.debtDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.debtDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.debtDate.required">Vui lòng chọn ngày lập</span>
                            </md-datepicker>
                            <md-field>
                                <label for="amount">Số tiền</label>
                                <md-input name="amount" :value="formatNumber(entity.amount)" ></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="form-body form-tab">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-tabs>
                                <md-tab id="tab-order" class="tab-content" md-label="Danh sách phải trả">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col l-12 m-12 c-12">
                                                <div class="tool-bar"></div>
                                                <table class="data-table-2"> 
                                                    <thead> 
                                                        <tr> 
                                                            <th style="width:100px;">#</th>
                                                            <th class="left" style="width:25%;">Mã phiếu phải trả</th>
                                                            <th class="left" style="width:5%;">Tháng</th>
                                                            <th class="left" style="width:5%;">Năm</th>
                                                            <th class="left" style="width:22%;">Mã đơn hàng</th>
                                                            <th class="left" style="width:25%;">Số bill/booking</th>
                                                            <th class="righ" style="width:10%;">Tổng tiền</th> 
                                                            <th style="width:8%;">Hành động</th> 
                                                        </tr> 
                                                    </thead> 
                                                    <tbody v-if="loadding == true" style="height: 100px;"> 
                                                        <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                    </tbody>
                                                    <tbody v-if="loadding == false"> 
                                                        <tr v-for="(item, index) in entity.details" :key="'request-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                            <td class="center">{{index + 1}}</td>
                                                            <td class="left">
                                                                {{ item.refundCode }}
                                                            </td>
                                                            <td class="center">
                                                                {{ item.refundMonth }}
                                                            </td>
                                                            <td class="center">
                                                                {{ item.refundYear }}
                                                            </td>
                                                            <td class="left">
                                                                {{ item.order.orderCode }}
                                                            </td>
                                                            <td class="left">
                                                                {{ item.order.booking.noBooking }}
                                                            </td>
                                                            <td style="padding-right: 5px; text-align: right;">{{formatNumber(item.pay)}}</td>
                                                            <td class="grid-action">
                                                                <a @click="delOrderRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                            </td>
                                                        </tr> 
                                                    </tbody> 
                                                </table> 
                                            </div>
                                        </div>
                                    </div>
                                </md-tab>
                            </md-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <companyList ref="companyList" title="vendor" :type="customerType" @close="closeCompany"/>
   </div>
</template>
<script>
    import debtCustomerService from '../../../api/debtCustomerService';
    import refundService from '../../../api/refundService';
    import companyService from '../../../api/companyService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import staffList from '../../../components/popup/_StaffList.vue';
    import companyList from '../../../components/popup/_CompanyList.vue';

    export default ({
        components: {
            staffList,
            companyList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật công nợ phải trả vendor'
        },
        data() {
            return {
                title: '',
                id: 0,
                selectedId: '',
                submitted: false,
                staffName: '',
                staffs: [],
                customerType: common.companyType.transport,
                loadding: false,
                companyName: '',
                companies: [],
                entity: { id: 0, type: 2, debtNumber: 0, details: [], orders: [], debtCode: '', debtDate: common.dateNow, companyId: 0, deptId: 0, staffId: 0, status: 0, amount: 0, note: ''},
                status: [ { value: 0, text: 'Chưa xử lý' }, { value: 1, text: 'Đang thu' }, { value: 2, text: 'Quyết toán' } ]
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật công nợ phải trả vendor';
               this.getById();
            }
            else{
               this.title = 'Thêm mới công nợ phải trả vendor';
               this.getByAccount();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            delOrderRow(item){
                const index = this.entity.orders.findIndex(x => x.orderId == item.orderId);
                this.entity.orders.splice(index, 1);
                this.entity.details = this.entity.details.filter(obj => !(obj.orderId == item.orderId));
                this.calSummary();
            },

            calSummary(){
                let amount = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    amount = parseFloat(amount) + parseFloat(this.entity.details[i].pay);
                }
                this.entity.amount = amount;
            },

            getRefunds(){
                this.setLoading(true);
                refundService.getForDebt(this.entity.companyId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.details = response.data;
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //OutSource
            closeCompany(item){
                this.companyName = item.companyAlias;
                this.entity.companyId = item.id;
                this.getRefunds();
                this.$refs.companyList.close();
            },

            getCompanySelected(val){
                this.entity.companyId = val.id;
                this.companyName = val.companyAlias;
                this.getRefunds();
            },

            openCompany(){
                this.$refs.companyList.open(this.customerType);
            },

            getCompanies(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.companies = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
            
            //Sales
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.entity.details.length <= 0){
                    messageBox.showWarning("vendor không có công nợ");
                    return;
                }
                this.entity.status = status;
                this.entity.type = 2;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                debtCustomerService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/debt-vendor');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                debtCustomerService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/debt-vendor');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                debtCustomerService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.companyName = response.data.company.companyAlias;
                        this.staffName = response.data.staff.fullName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.staffName = response.data.fullName;
                            this.entity.deptId = response.data.department.id;
                            this.entity.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getCode(staffId){
                this.setLoading(true);
                debtCustomerService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.debtCode = response.data.code;
                        this.entity.debtNumber = response.data.number;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/debt-vendor');
            }
        },
        watch: { 
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                debtCode: { required },
                status: { required },
                companyId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                debtDate: { required }
            }
        }
    })
</script>
